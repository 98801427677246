import ButtonShadow from 'components/ButtonShadow/ButtonShadow'
import { Formik } from 'formik'
import React, { useCallback, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { RecipientFormValues } from '../../RecipientForm'
import * as Styled from './BankAccountForm.styled'
import SimpleTextField from 'components/Fields/SimpleTextField/SimpleTextField'
import { CircularProgress, MenuItem } from '@material-ui/core'
import { Colors } from 'styles'
import SimpleSelectField from 'components/Fields/SimpleSelectField/SimpleSelectField'
import { getBanks } from 'services/brasilApi'
import AutocompleteField from 'components/Fields/AutocompleteField/AutocompleteField'

const bankAccountTypes = ['Corrente', 'Poupança']

const validationSchema = Yup.object().shape({
  holderName: Yup.string()
    .trim()
    .required('Titular da conta não preenchido')
    .max(30, 'O titular da conta não pode passar de 30 caracteres'),
  bankCode: Yup.string().trim().required('Banco não preenchido'),
  branchNumber: Yup.string()
    .trim()
    .required('Agência não preenchida')
    .matches(/^\d{1,4}(-\d)?$/, 'Agência inválida'),
  accountNumber: Yup.string()
    .trim()
    .required('Conta não preenchida')
    .matches(/-/, 'Separe o dígito utilizando "-"')
    .matches(/^\d{1,13}-\d$/, 'Conta inválida'),
  type: Yup.string().trim().required('Tipo não preenchido'),
})

interface FormValues {
  holderName: string
  bankCode: string
  branchNumber: string
  accountNumber: string
  type: 'Corrente' | 'Poupança' | ''
}

interface IBank {
  name: string
  code: number
}

interface RecipientFormProps {
  values: RecipientFormValues
  updateValues: (values: RecipientFormValues, fromStep: string) => Promise<void>
  currentStep: string
  step: string
  previousStep: () => void
}

const BankAccountForm: React.FC<RecipientFormProps> = ({
  updateValues,
  values: savedValues,
  currentStep,
  step,
  previousStep,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [banks, setBanks] = useState<IBank[]>([])
  const [hasErrorGettingBanks, setHasErrorGettingBanks] = useState(false)
  const [initialValues, setInitialValues] = useState<FormValues>({
    holderName: savedValues.bankAccount?.holderName,
    bankCode: savedValues.bankAccount?.bankCode ?? '',
    branchNumber: savedValues.bankAccount?.branchNumber,
    accountNumber: savedValues.bankAccount?.accountNumber,
    type: savedValues.bankAccount?.type ?? '',
  })

  const onSubmit = useCallback(
    async (formValues: FormValues): Promise<void> => {
      await updateValues(
        {
          ...savedValues,
          bankAccount: {
            holderName: formValues.holderName,
            bankCode: formValues.bankCode,
            branchNumber: formValues.branchNumber,
            accountNumber: formValues.accountNumber,
            type: formValues.type,
          },
        },
        step,
      )
    },
    [updateValues, step, savedValues],
  )

  useEffect(() => {
    setInitialValues({
      holderName: savedValues.bankAccount?.holderName,
      bankCode: savedValues.bankAccount?.bankCode ?? '',
      branchNumber: savedValues.bankAccount?.branchNumber,
      accountNumber: savedValues.bankAccount?.accountNumber,
      type: savedValues.bankAccount?.type ?? '',
    })
  }, [savedValues])

  const fetchBanks = useCallback(async () => {
    setIsLoading(true)
    setHasErrorGettingBanks(false)
    const banksOrError = await getBanks()

    if (banksOrError.hasFailed()) {
      setIsLoading(false)
      setHasErrorGettingBanks(true)
      return
    }

    setIsLoading(false)
    setBanks(
      banksOrError.data
        .map((bank) => ({
          name: bank.name,
          code: bank.code,
        }))
        .filter((bank) => typeof bank.code === 'number')
        .sort((a, b) => a.name.localeCompare(b.name)),
    )
  }, [])

  useEffect(() => {
    if (currentStep !== step || banks.length) return
    fetchBanks()
  }, [fetchBanks, currentStep, step, banks])

  return currentStep !== step ? null : (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, values, touched, errors }) => {
        return (
          <Styled.FlexForm>
            {isLoading && (
              <Styled.InfoContainer>
                <CircularProgress style={{ color: Colors.PURPLE[400] }} />
                <Styled.LoadingText>Carregando...</Styled.LoadingText>
              </Styled.InfoContainer>
            )}
            {hasErrorGettingBanks && !isLoading && (
              <Styled.InfoContainer>
                <Styled.ErrorText>
                  Falha ao buscar as opções de bancos
                </Styled.ErrorText>
                <ButtonShadow
                  label="Tentar novamente"
                  variant="secondary"
                  onPress={fetchBanks}
                />
              </Styled.InfoContainer>
            )}

            <Styled.Row>
              <Styled.Info>
                {savedValues.documentNumber?.length === 11
                  ? 'A conta bancária deve pertencer a uma Pessoa Física'
                  : 'A conta bancária deve pertencer a uma Pessoa Jurídica'}
              </Styled.Info>
            </Styled.Row>

            <Styled.Row>
              <SimpleTextField
                name="holderName"
                label="Titular da conta"
                placeholder="Nome do titular da conta"
                variant="outlined"
                error={!!(touched.holderName && errors?.holderName)}
                helperText={touched.holderName && errors?.holderName}
                fullWidth
                darkMode
              />
            </Styled.Row>

            <Styled.Row>
              <AutocompleteField
                name="bankCode"
                label="Banco"
                darkMode
                fullWidth
                error={!!(touched.bankCode && errors?.bankCode)}
                helperText={touched.bankCode && errors?.bankCode}
                wrapperStyle={{ maxWidth: '50%' }}
                options={[{ label: 'Selecione um banco', value: '' }].concat(
                  banks.map(({ name, code }) => {
                    const bankCode = code.toString().padStart(3, '0')
                    return {
                      label: `${bankCode} - ${name}`,
                      value: bankCode,
                    }
                  }),
                )}
              />

              <SimpleSelectField
                name="type"
                label="Tipo"
                variant="outlined"
                displayEmpty
                darkMode
                fullWidth
                error={!!(touched.type && errors?.type)}
                helperText={touched.type && errors?.type}
                value={values.type}
              >
                <MenuItem value="">Selecione o tipo da conta</MenuItem>
                {bankAccountTypes.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </SimpleSelectField>
            </Styled.Row>

            <Styled.Row>
              <SimpleTextField
                name="branchNumber"
                label="Agência"
                placeholder="Agência"
                variant="outlined"
                error={!!(touched.branchNumber && errors?.branchNumber)}
                helperText={touched.branchNumber && errors?.branchNumber}
                fullWidth
                darkMode
              />

              <SimpleTextField
                name="accountNumber"
                label="Conta"
                placeholder="Conta"
                variant="outlined"
                error={!!(touched.accountNumber && errors?.accountNumber)}
                helperText={touched.accountNumber && errors?.accountNumber}
                fullWidth
                darkMode
              />
            </Styled.Row>

            <Styled.ActionsContainer>
              <ButtonShadow
                label="Voltar"
                variant="secondary"
                onPress={previousStep}
              />
              <ButtonShadow label="Continuar" onPress={handleSubmit} />
            </Styled.ActionsContainer>
          </Styled.FlexForm>
        )
      }}
    </Formik>
  )
}

export default BankAccountForm
