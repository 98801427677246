import styled from 'styled-components'
import { Colors } from 'styles'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`

export const Title = styled.h1`
  font-family: 'Gellix';
  font-style: normal;
  font-weight: 700;
  font-size: 1.4rem;
  text-transform: uppercase;
  color: #000000;
  margin: 0;
`

export const CurrentStepContainer = styled.div`
  display: flex;
  gap: 8px;
`

export const StepDot = styled.div<{
  isCurrent?: boolean
  isDisabled?: boolean
}>`
  width: 16px;
  height: 16px;
  background-color: ${({ isCurrent, isDisabled }) =>
    isCurrent
      ? Colors.PURPLE[400]
      : isDisabled
      ? Colors.GREY[100]
      : Colors.GREY[150]};
  border-radius: 16px;
  cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
`
