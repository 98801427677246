import { Form } from 'formik'
import styled from 'styled-components'
import { Colors, Typography } from 'styles'

export const FlexForm = styled(Form)`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: start;
  padding: 0px 0px 24px 0px;
  position: relative;
  max-width: 600px;
`

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 4px;
`

export const Row = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
`

export const InfoContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const LoadingText = styled.p`
  font-weight: 300;
  font-size: 1.2rem;
  color: ${Colors.GREY[400]};
  font-family: Inter;
  letter-spacing: -${Typography.LETTER_SPACING_1}px;
`

export const ErrorText = styled.p`
  font-weight: 300;
  font-size: 1.2rem;
  color: ${Colors.GREY[400]};
  font-family: Inter;
  letter-spacing: -${Typography.LETTER_SPACING_1}px;
`

export const Info = styled.p`
  font-weight: 300;
  font-size: 0.8rem;
  color: ${Colors.GREY[400]};
  font-family: Inter;
  letter-spacing: -${Typography.LETTER_SPACING_1}px;
  font-style: italic;
`
