import { CircularProgress } from '@material-ui/core'
import Steps from 'components/Steps/Steps'
import React, { useCallback, useEffect, useState } from 'react'
import DocumentNumberForm from './Forms/DocumentNumberForm/DocumentNumberForm'
import * as Styled from './RecipientForm.styled'
import { Container } from './RecipientForm.styled'
import IndividualDataForm from './Forms/IndividualDataForm/IndividualDataForm'
import AddressForm from './Forms/AddressForm/AddressForm'
import BankAccountForm from './Forms/BankAccountForm/BankAccountForm'
import CompanyDataForm from './Forms/CompanyDataForm/CompanyDataForm'
import ManagingPartnerForm from './Forms/ManagingPartnerForm/ManagingPartnerForm'
import ManagingPartnerAddressForm from './Forms/ManagingPartnerAddressForm/ManagingPartnerAddressForm'
import { Colors } from 'styles'
import ButtonShadow from 'components/ButtonShadow/ButtonShadow'
import { createRecipient, getPartialRecipient } from 'services/api'
import moment from 'moment-timezone'

export interface RecipientFormValues {
  documentNumber?: string
  email?: string
  phoneNumber?: string

  name?: string
  birthDate?: string
  monthlyIncome?: string
  professionalOccupation?: string

  address?: {
    zipCode?: string
    street?: string
    number?: string
    neighborhood?: string
    city?: string
    state?: string
    complementary?: string
  }

  bankAccount?: {
    holderName?: string
    bankCode?: string
    branchNumber?: string
    accountNumber?: string
    type?: 'Corrente' | 'Poupança' | ''
  }

  companyName?: string
  annualRevenue?: string
  managingPartner?: {
    documentNumber?: string
    email?: string
    name?: string
    birthDate?: string
    monthlyIncome?: string
    professionalOccupation?: string
    phoneNumber?: string
    address?: {
      useCompanyAddress?: 'useCompanyAddress' | ''
      zipCode?: string
      street?: string
      number?: string
      neighborhood?: string
      city?: string
      state?: string
      complementary?: string
    }
  }
}

const INITIAL_STEP = ['CPF/CNPJ'] as const

const COMPANY_STEPS = [
  'CPF/CNPJ',
  'Dados da empresa',
  'Endereço',
  'Conta bancária',
  'Representante legal',
  'Endereço representante legal',
] as const

const INDIVIDUAL_STEPS = [
  'CPF/CNPJ',
  'Dados pessoais',
  'Endereço',
  'Conta bancária',
] as const

function getSteps(documentNumber: string) {
  return documentNumber
    ? documentNumber.length > 11
      ? COMPANY_STEPS
      : INDIVIDUAL_STEPS
    : INITIAL_STEP
}

interface IError {
  message?: string
  tryAgainFunctionName?: string
}

interface RecipientFormProps {
  podcastId: string
  handleCreatedRecipient: () => void
}

const RecipientForm: React.FC<RecipientFormProps> = ({
  podcastId,
  handleCreatedRecipient,
}) => {
  const [currentStep, setCurrentStep] = useState('CPF/CNPJ')
  const [steps, setSteps] = useState<readonly string[]>(INITIAL_STEP)
  const [values, setValues] = useState<RecipientFormValues>({})
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<IError>({})

  const fetchCurrentRecipient = useCallback(async () => {
    setIsLoading(true)
    const partialRecipientOrError = await getPartialRecipient(podcastId)

    if (partialRecipientOrError.hasFailed()) {
      setIsLoading(false)
      setError({
        message: 'Falha ao buscar os dados.',
        tryAgainFunctionName: 'fetchCurrentRecipient',
      })
      return
    }

    const { data } = partialRecipientOrError
    setValues({
      documentNumber: data.documentNumber?.replace(/\D+/g, ''),
      email: data.email ?? undefined,
      phoneNumber: data.phoneNumber?.replace(/\D+/g, ''),

      name: data.name ?? undefined,
      birthDate: data.birthDate
        ? moment(data.birthDate).format('DDMMYYYY')
        : undefined,
      monthlyIncome: data.monthlyIncome ?? undefined,
      professionalOccupation: data.professionalOccupation ?? undefined,

      address: data.address
        ? {
            zipCode: data.address.zipCode?.replace(/\D+/g, ''),
            street: data.address.street ?? undefined,
            number: data.address.number ?? undefined,
            neighborhood: data.address.neighborhood ?? undefined,
            city: data.address.city ?? undefined,
            state: data.address.state ?? '',
            complementary:
              typeof data.address.complementary === 'string'
                ? data.address.complementary === '-'
                  ? ''
                  : data.address.complementary
                : undefined,
          }
        : undefined,

      bankAccount: data.bankAccount
        ? {
            holderName: data.bankAccount.holderName ?? undefined,
            bankCode: data.bankAccount.bankCode ?? '',
            branchNumber: data.bankAccount.branchNumber ?? undefined,
            accountNumber: data.bankAccount.accountNumber ?? undefined,
            type:
              data.bankAccount.type === 'checking'
                ? 'Corrente'
                : data.bankAccount.type === 'savings'
                ? 'Poupança'
                : '',
          }
        : undefined,

      companyName: data.companyName ?? undefined,
      annualRevenue: data.annualRevenue ?? undefined,
      managingPartner: data.managingPartner
        ? {
            documentNumber: data.managingPartner.documentNumber?.replace(
              /\D+/g,
              '',
            ),
            email: data.managingPartner.email ?? undefined,
            name: data.managingPartner.name ?? undefined,
            birthDate: data.managingPartner.birthDate
              ? moment(data.managingPartner.birthDate).format('DDMMYYYY')
              : undefined,
            monthlyIncome: data.managingPartner.monthlyIncome ?? undefined,
            professionalOccupation:
              data.managingPartner.professionalOccupation ?? undefined,
            phoneNumber: data.managingPartner.phoneNumber?.replace(/\D+/g, ''),
            address: data.managingPartner.address
              ? {
                  useCompanyAddress: '',
                  zipCode: data.managingPartner.address.zipCode?.replace(
                    /\D+/g,
                    '',
                  ),
                  street: data.managingPartner.address.street ?? undefined,
                  number: data.managingPartner.address.number ?? undefined,
                  neighborhood:
                    data.managingPartner.address.neighborhood ?? undefined,
                  city: data.managingPartner.address.city ?? undefined,
                  state: data.managingPartner.address.state ?? '',
                  complementary:
                    typeof data.managingPartner.address.complementary ===
                    'string'
                      ? data.managingPartner.address.complementary === '-'
                        ? ''
                        : data.managingPartner.address.complementary
                      : undefined,
                }
              : undefined,
          }
        : undefined,
    })
    setIsLoading(false)
  }, [podcastId])

  useEffect(() => {
    setSteps(getSteps(values.documentNumber))
  }, [values.documentNumber])

  const handleCreateRecipient = useCallback(
    async (values: RecipientFormValues) => {
      setIsLoading(true)

      const result = await createRecipient(podcastId, {
        documentNumber: values.documentNumber,
        email: values.email,
        phoneNumber: values.phoneNumber,
        address: {
          street: values.address?.street,
          number: values.address?.number,
          neighborhood: values.address?.neighborhood,
          city: values.address?.city,
          state: values.address?.state,
          zipCode: values.address?.zipCode,
          complementary: values.address?.complementary || '-',
        },
        bankAccount: {
          holderName: values.bankAccount?.holderName,
          bankCode: values.bankAccount?.bankCode?.padStart(3, '0'),
          branchNumber: values.bankAccount?.branchNumber,
          accountNumber: values.bankAccount?.accountNumber,
          type:
            values.bankAccount?.type === 'Corrente'
              ? 'checking'
              : values.bankAccount?.type === 'Poupança'
              ? 'savings'
              : undefined,
        },

        companyName: values.companyName,
        annualRevenue: values.annualRevenue,
        managingPartner: values.managingPartner
          ? {
              documentNumber: values.managingPartner?.documentNumber,
              email: values.managingPartner?.email,
              name: values.managingPartner?.name,
              birthDate: values.managingPartner?.birthDate?.replace(
                /^(\d{2})(\d{2})(\d{4})$/,
                '$3-$2-$1T00:00:00.000-03:00',
              ),
              monthlyIncome: values.managingPartner?.monthlyIncome,
              professionalOccupation:
                values.managingPartner?.professionalOccupation,
              phoneNumber: values.managingPartner?.phoneNumber,
              address: {
                street: values.managingPartner?.address.street,
                number: values.managingPartner?.address.number,
                neighborhood: values.managingPartner?.address.neighborhood,
                city: values.managingPartner?.address.city,
                state: values.managingPartner?.address.state,
                zipCode: values.managingPartner?.address.zipCode,
                complementary:
                  values.managingPartner?.address.complementary || '-',
              },
            }
          : undefined,

        name: values.name,
        birthDate: values.birthDate?.replace(
          /^(\d{2})(\d{2})(\d{4})$/,
          '$3-$2-$1T00:00:00.000-03:00',
        ),
        monthlyIncome: values.monthlyIncome,
        professionalOccupation: values.professionalOccupation,
      })

      if (result.hasFailed()) {
        setIsLoading(false)
        setError({
          message: 'Falha ao criar recebedor',
          tryAgainFunctionName: 'handleCreateRecipient',
        })
        return
      }

      handleCreatedRecipient()
      setIsLoading(false)
    },
    [podcastId, handleCreatedRecipient],
  )

  const nextStep = useCallback(
    (fromStep: string, newValues?: RecipientFormValues) => {
      const currentSteps = getSteps(
        newValues.documentNumber ?? values.documentNumber,
      )

      const currentStepIndex = currentSteps.findIndex(
        (value) => value === fromStep,
      )

      if (currentStepIndex === currentSteps.length - 1) {
        handleCreateRecipient(newValues)
        return
      }

      setCurrentStep(currentSteps[currentStepIndex + 1])
      return
    },
    [values.documentNumber, handleCreateRecipient],
  )

  const previousStep = useCallback(() => {
    const currentSteps = getSteps(values.documentNumber)

    const currentStepIndex = currentSteps.findIndex(
      (value) => value === currentStep,
    )
    if (currentStepIndex === 0) return
    setCurrentStep(currentSteps[Math.max(currentStepIndex - 1, 0)])
  }, [currentStep, values.documentNumber])

  const handleUpdateValues = useCallback(
    async (values: RecipientFormValues, fromStep: string): Promise<void> => {
      setValues(values)
      nextStep(fromStep, values)
    },
    [nextStep],
  )

  useEffect(() => {
    fetchCurrentRecipient()
  }, [fetchCurrentRecipient])

  return (
    <Container>
      {isLoading ? (
        <Styled.InfoContainer>
          <CircularProgress style={{ color: Colors.PURPLE[400] }} />
          <Styled.InfoText>Carregando...</Styled.InfoText>
        </Styled.InfoContainer>
      ) : error.message ? (
        <Styled.InfoContainer>
          <Styled.InfoText>{error.message}</Styled.InfoText>
          <ButtonShadow
            label="Tentar novamente"
            variant="secondary"
            onPress={
              error.tryAgainFunctionName === 'fetchCurrentRecipient'
                ? fetchCurrentRecipient
                : error.tryAgainFunctionName === 'handleCreateRecipient'
                ? () => handleCreateRecipient(values)
                : undefined
            }
          />
        </Styled.InfoContainer>
      ) : (
        <>
          <Styled.StepsContainer>
            <Steps
              steps={steps}
              hiddenSteps={['CPF/CNPJ']}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
            />
          </Styled.StepsContainer>

          <DocumentNumberForm
            values={values}
            updateValues={handleUpdateValues}
            step="CPF/CNPJ"
            currentStep={currentStep}
          />

          <IndividualDataForm
            values={values}
            updateValues={handleUpdateValues}
            step="Dados pessoais"
            currentStep={currentStep}
            previousStep={previousStep}
          />

          <AddressForm
            values={values}
            updateValues={handleUpdateValues}
            step="Endereço"
            currentStep={currentStep}
            previousStep={previousStep}
          />

          <BankAccountForm
            values={values}
            updateValues={handleUpdateValues}
            step="Conta bancária"
            currentStep={currentStep}
            previousStep={previousStep}
          />

          <CompanyDataForm
            values={values}
            updateValues={handleUpdateValues}
            step="Dados da empresa"
            currentStep={currentStep}
            previousStep={previousStep}
          />

          <ManagingPartnerForm
            values={values}
            updateValues={handleUpdateValues}
            step="Representante legal"
            currentStep={currentStep}
            previousStep={previousStep}
          />

          <ManagingPartnerAddressForm
            values={values}
            updateValues={handleUpdateValues}
            step="Endereço representante legal"
            currentStep={currentStep}
            previousStep={previousStep}
          />
        </>
      )}
    </Container>
  )
}

export default RecipientForm
