import styled from 'styled-components'
import { Colors, Typography } from 'styles'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 32px 20px;
  gap: 16px;
`

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
`

export const Title = styled.p`
  font-weight: 800;
  font-size: 2.4rem;
  color: ${Colors.GREY[400]};
  font-family: Inter;
  letter-spacing: -${Typography.LETTER_SPACING_1}px;
  text-align: center;
  margin: 0;
`

export const Description = styled.p`
  font-weight: 400;
  font-size: 1rem;
  color: ${Colors.GREY[400]};
  font-family: Inter;
  letter-spacing: -${Typography.LETTER_SPACING_1}px;
  margin: 0;
`

export const Url = styled.a`
  color: ${Colors.PURPLE[400]};
`
