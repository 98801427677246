import { CircularProgress, MenuItem } from '@material-ui/core'
import ButtonShadow from 'components/ButtonShadow/ButtonShadow'
import NumberFormatField from 'components/Fields/NumberFormatField/NumberFormatField'
import SimpleCheckboxField from 'components/Fields/SimpleCheckboxField/SimpleCheckboxField'
import SimpleSelectField from 'components/Fields/SimpleSelectField/SimpleSelectField'
import SimpleTextField from 'components/Fields/SimpleTextField/SimpleTextField'
import { Formik } from 'formik'
import React, { useCallback, useEffect, useState } from 'react'
import { Colors } from 'styles'
import * as Yup from 'yup'
import { RecipientFormValues } from '../../RecipientForm'
import * as Styled from './ManagingPartnerAddressForm.styled'
import { getCep } from 'services/brasilApi'

const brazilStates = [
  'AC',
  'AL',
  'AP',
  'AM',
  'BA',
  'CE',
  'DF',
  'ES',
  'GO',
  'MA',
  'MT',
  'MS',
  'MG',
  'PA',
  'PB',
  'PR',
  'PE',
  'PI',
  'RJ',
  'RN',
  'RS',
  'RO',
  'RR',
  'SC',
  'SP',
  'SE',
  'TO',
]

const validationSchema = Yup.object().shape({
  zipCode: Yup.string()
    .trim()
    .required('CEP não preenchido')
    .matches(/^\d{8}$/, 'CEP inválido'),
  street: Yup.string().trim().required('Rua não preenchida'),
  number: Yup.string().trim().required('Número não preenchido'),
  neighborhood: Yup.string().trim().required('Bairro não preenchido'),
  city: Yup.string().trim().required('Cidade não preenchida'),
  state: Yup.string()
    .trim()
    .oneOf(brazilStates, 'Estado inválido')
    .required('Estado não preenchido'),
  complementary: Yup.string(),
})

interface FormValues {
  useCompanyAddress?: 'useCompanyAddress' | ''
  zipCode: string
  street: string
  number: string
  neighborhood: string
  city: string
  state: string
  complementary: string
}

interface RecipientFormProps {
  values: RecipientFormValues
  updateValues: (values: RecipientFormValues, step: string) => Promise<void>
  currentStep: string
  step: string
  previousStep: () => void
}

const ManagingPartnerAddressForm: React.FC<RecipientFormProps> = ({
  updateValues,
  values: savedValues,
  currentStep,
  step,
  previousStep,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [initialValues, setInitialValues] = useState<FormValues>({
    useCompanyAddress: savedValues.managingPartner?.address?.useCompanyAddress,
    zipCode: savedValues.managingPartner?.address?.zipCode,
    street: savedValues.managingPartner?.address?.street,
    number: savedValues.managingPartner?.address?.number,
    neighborhood: savedValues.managingPartner?.address?.neighborhood,
    city: savedValues.managingPartner?.address?.city,
    state: savedValues.managingPartner?.address?.state ?? '',
    complementary: savedValues.managingPartner?.address?.complementary,
  })

  const onSubmit = useCallback(
    async (formValues: FormValues): Promise<void> => {
      await updateValues(
        {
          ...savedValues,
          managingPartner: {
            ...savedValues.managingPartner,
            address: {
              useCompanyAddress: formValues.useCompanyAddress,
              zipCode: formValues.zipCode,
              street: formValues.street,
              number: formValues.number,
              neighborhood: formValues.neighborhood,
              city: formValues.city,
              state: formValues.state,
              complementary: formValues.complementary,
            },
          },
        },
        step,
      )
    },
    [updateValues, step, savedValues],
  )

  useEffect(() => {
    const useCompanyAddress =
      !!savedValues.managingPartner?.address?.useCompanyAddress
    const address = useCompanyAddress
      ? savedValues.address
      : savedValues.managingPartner?.address

    setInitialValues({
      useCompanyAddress:
        savedValues.managingPartner?.address?.useCompanyAddress,
      zipCode: address?.zipCode,
      street: address?.street,
      number: address?.number,
      neighborhood: address?.neighborhood,
      city: address?.city,
      state: address?.state ?? '',
      complementary: address?.complementary,
    })
  }, [savedValues.managingPartner?.address, savedValues.address])

  const fetchCep = useCallback(
    async (
      newZipCode: string,
      values: FormValues,
      setValues: (values: FormValues) => void,
    ) => {
      setIsLoading(true)

      const cepOrError = await getCep(newZipCode)

      if (cepOrError.hasFailed()) {
        setIsLoading(false)
        return
      }

      setIsLoading(false)
      setValues({
        zipCode: cepOrError.data.cep,
        street: cepOrError.data.street,
        number: values.number,
        neighborhood: cepOrError.data.neighborhood,
        city: cepOrError.data.city,
        state: cepOrError.data.state,
        complementary: values.complementary,
      })
    },
    [],
  )

  return currentStep !== step ? null : (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, values, touched, errors, setValues, setTouched }) => {
        const useCompanyAddress = !!values.useCompanyAddress

        return (
          <Styled.FlexForm>
            {isLoading && (
              <Styled.LoadingContainer>
                <CircularProgress style={{ color: Colors.PURPLE[400] }} />
                <Styled.LoadingText>Carregando...</Styled.LoadingText>
              </Styled.LoadingContainer>
            )}

            <Styled.Row>
              <SimpleCheckboxField
                name="useCompanyAddress"
                label="Mesmo endereço da empresa"
                darkMode
                onChange={(event) => {
                  const useCompanyAddress = event.target.checked

                  setValues(
                    useCompanyAddress
                      ? {
                          useCompanyAddress: 'useCompanyAddress',
                          zipCode: savedValues.address?.zipCode,
                          street: savedValues.address?.street,
                          number: savedValues.address?.number,
                          neighborhood: savedValues.address?.neighborhood,
                          city: savedValues.address?.city,
                          state: savedValues.address?.state,
                          complementary: savedValues.address?.complementary,
                        }
                      : {
                          useCompanyAddress: '',
                          zipCode: savedValues.address?.zipCode,
                          street: savedValues.address?.street,
                          number: savedValues.address?.number,
                          neighborhood: savedValues.address?.neighborhood,
                          city: savedValues.address?.city,
                          state: savedValues.address?.state,
                          complementary: savedValues.address?.complementary,
                        },
                  )
                  setTouched({})
                }}
              />
            </Styled.Row>

            <Styled.Row>
              <NumberFormatField
                name="zipCode"
                variant="outlined"
                label="CEP"
                fullWidth
                format={'#####-###'}
                placeholder="_____-___"
                mask={'_'}
                error={!!(touched.zipCode && errors?.zipCode)}
                helperText={touched.zipCode && (errors?.zipCode as string)}
                darkMode
                disabled={useCompanyAddress}
                value={values.zipCode}
                onBlur={(event) => {
                  const newZipCode = event.target.value.replace(/\D+/g, '')
                  if (newZipCode.length < 8) return
                  fetchCep(newZipCode, values, setValues)
                }}
                allowLeadingZeros
                hasEmptyValue
              />
            </Styled.Row>

            <Styled.Row>
              <SimpleTextField
                name="city"
                label="Cidade"
                placeholder="Cidade"
                variant="outlined"
                error={!!(touched.city && errors?.city)}
                helperText={touched.city && errors?.city}
                fullWidth
                darkMode
                disabled={useCompanyAddress}
              />

              <SimpleSelectField
                name="state"
                label="Estado"
                variant="outlined"
                displayEmpty
                darkMode
                fullWidth
                error={!!(touched.state && errors?.state)}
                helperText={touched.state && errors?.state}
                disabled={useCompanyAddress}
              >
                <MenuItem value="">Selecione um estado</MenuItem>
                {brazilStates.map((state) => (
                  <MenuItem key={state} value={state}>
                    {state}
                  </MenuItem>
                ))}
              </SimpleSelectField>
            </Styled.Row>

            <Styled.Row>
              <SimpleTextField
                name="neighborhood"
                label="Bairro"
                placeholder="Bairro"
                variant="outlined"
                error={!!(touched.neighborhood && errors?.neighborhood)}
                helperText={touched.neighborhood && errors?.neighborhood}
                fullWidth
                darkMode
                disabled={useCompanyAddress}
              />
            </Styled.Row>

            <Styled.Row>
              <SimpleTextField
                name="street"
                label="Rua"
                placeholder="Rua"
                variant="outlined"
                error={!!(touched.street && errors?.street)}
                helperText={touched.street && errors?.street}
                fullWidth
                darkMode
                disabled={useCompanyAddress}
              />
            </Styled.Row>

            <Styled.Row>
              <SimpleTextField
                name="number"
                label="Número"
                placeholder="Número"
                variant="outlined"
                error={!!(touched.number && errors?.number)}
                helperText={touched.number && errors?.number}
                fullWidth
                darkMode
                disabled={useCompanyAddress}
              />

              <SimpleTextField
                name="complementary"
                label="Complemento"
                placeholder="Complemento"
                variant="outlined"
                error={!!(touched.complementary && errors?.complementary)}
                helperText={touched.complementary && errors?.complementary}
                fullWidth
                darkMode
                disabled={useCompanyAddress}
              />
            </Styled.Row>

            <Styled.ActionsContainer>
              <ButtonShadow
                label="Voltar"
                variant="secondary"
                onPress={previousStep}
              />
              <ButtonShadow label="Continuar" onPress={handleSubmit} />
            </Styled.ActionsContainer>
          </Styled.FlexForm>
        )
      }}
    </Formik>
  )
}

export default ManagingPartnerAddressForm
