import 'abortcontroller-polyfill'
import { ApiFetch } from './ApiFetch'
import cepPromise, { CEP } from 'cep-promise'

const apiBaseUrl = `https://brasilapi.com.br/api`

export const getBanks = async (): Promise<ApiFetch> =>
  ApiFetch.get(`${apiBaseUrl}/banks/v1`)

interface GetCepResult {
  hasFailed: () => boolean
  data?: CEP
  error?: string
}

export const getCep = async (cep: string): Promise<GetCepResult> => {
  try {
    const address = await cepPromise(cep)
    return {
      hasFailed: () => false,
      data: address,
    }
  } catch (error) {
    return {
      hasFailed: () => true,
      error: error.message,
    }
  }
}
