import { InputLabel } from '@material-ui/core'
import { Form } from 'formik'
import styled from 'styled-components'
import { Colors, Typography } from 'styles'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: 100%;
`

export const FlexForm = styled(Form)`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: start;
  padding: 24px 0;
`

export const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StyledLabel = styled(InputLabel)`
  margin-right: 12px;

  &.MuiFormLabel-root {
    font-family: 'Gellix';
    font-weight: bold;
    font-size: 22px;
    color: black;
  }
`

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`

export const StepsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 12px;
`

export const InfoContainer = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
`

export const InfoText = styled.p`
  font-weight: 300;
  font-size: 1.2rem;
  color: ${Colors.GREY[400]};
  font-family: Inter;
  letter-spacing: -${Typography.LETTER_SPACING_1}px;
`
