import { CheckboxProps, FormHelperText } from '@material-ui/core'
import { useField } from 'formik'
import React from 'react'
import * as Styled from './SimpleCheckboxField.styled'

interface CheckboxFieldProps extends CheckboxProps {
  name: string
  label?: string | React.ReactElement
  helperText?: string
  error?: boolean
  darkMode?: boolean
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void
}

const SimpleCheckboxField: React.FC<CheckboxFieldProps> = ({
  name,
  label,
  helperText,
  error,
  darkMode,
  onChange,
}) => {
  const [field, , helpers] = useField(name)

  return (
    <Styled.Wrapper>
      <Styled.FlexWrapper>
        <Styled.StyledCheckbox
          id={name}
          name={name}
          value={name}
          checked={field.value === name}
          color="primary"
          onChange={
            onChange
              ? onChange
              : (event) => {
                  helpers.setValue(event.target.checked ? name : '')
                }
          }
        />
        {label && (
          <Styled.StyledLabel htmlFor={name} darkMode={darkMode}>
            {label}
          </Styled.StyledLabel>
        )}
      </Styled.FlexWrapper>
      {helperText && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
    </Styled.Wrapper>
  )
}

export default SimpleCheckboxField
