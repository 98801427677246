import { TextField } from '@material-ui/core'
import styled, { css } from 'styled-components'
import { Colors, Typography } from 'styles'

export const Wrapper = styled.div<{ fullWidth?: boolean }>`
  display: flex;
  flex-direction: column;
  margin: 12px 0;
  ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
    `}
`

export const StyledLabel = styled.label.attrs(
  (props: { darkMode?: boolean }) => props,
)`
  font-weight: ${(props) => (props.darkMode ? '300' : 'bold')};
  font-size: ${(props) => (props.darkMode ? '1rem' : '22px')};
  margin-bottom: ${(props) => (props.darkMode ? '8px' : '0')};
  color: ${(props) => (props.darkMode ? Colors.GREY[400] : 'inherit')};
  margin-right: 8px;
  font-family: Inter;
  letter-spacing: -${Typography.LETTER_SPACING_1}px;
`

export const StyledTextField = styled(TextField).attrs(
  (props: { darkMode?: boolean; error?: boolean }) => props,
)`
  border: 1px solid ${(props) => (props.error ? '#f44336' : 'black')} !important;
  border-radius: ${(props) => (props.darkMode ? '24px' : '4px')} !important;

  & .MuiInputBase-input {
    font-family: Inter;
    font-size: 18px;
    padding: ${(props) => (props.darkMode ? '12px 18px' : '18.5px 14px')};
    outline-offset: 0px;
  }

  & .MuiOutlinedInput-notchedOutline {
    border: 0;
  }
`
