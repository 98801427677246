import { FormHelperText, SelectProps } from '@material-ui/core'
import { useField } from 'formik'
import React from 'react'
import * as Styled from './SimpleSelectField.styled'

interface SelectFieldProps extends SelectProps {
  name: string
  label?: string | React.ReactElement
  helperText?: string
  error?: boolean
  fullWidth?: boolean
  darkMode?: boolean
  wrapperStyle?: React.CSSProperties
}

const SimpleSelectField: React.FC<SelectFieldProps> = ({
  name,
  label,
  helperText,
  error,
  children,
  fullWidth,
  darkMode,
  wrapperStyle,
  ...props
}) => {
  const [field] = useField(name)

  return (
    <Styled.Wrapper fullWidth={fullWidth} style={wrapperStyle}>
      {label && (
        <Styled.StyledLabel htmlFor={name} darkMode={darkMode}>
          {label}
        </Styled.StyledLabel>
      )}
      <Styled.StyledSelect
        id={name}
        label={label}
        error={error}
        fullWidth={fullWidth}
        darkMode={darkMode}
        {...props}
        {...field}
      >
        {children}
      </Styled.StyledSelect>
      {helperText && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
    </Styled.Wrapper>
  )
}

export default SimpleSelectField
