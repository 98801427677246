import styled, { css } from 'styled-components'
import { Checkbox, InputLabel } from '@material-ui/core'
import { Colors, Typography } from 'styles'

export const Wrapper = styled.div<{ fullWidth?: boolean }>`
  display: flex;
  flex-direction: column;
  margin: 12px 0;
  ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
    `}
`

export const StyledLabel = styled(InputLabel).attrs(
  (props: { darkMode?: boolean }) => props,
)`
  &.MuiFormLabel-root {
    font-weight: ${(props) => (props.darkMode ? '300' : 'bold')};
    font-size: ${(props) => (props.darkMode ? '1rem' : '22px')};
    color: ${(props) => (props.darkMode ? Colors.GREY[400] : 'inherit')};
    font-family: Inter;
    letter-spacing: -${Typography.LETTER_SPACING_1}px;
    cursor: pointer;
    user-select: none;
  }
`

export const StyledCheckbox = styled(Checkbox)`
  padding: 0px 9px 0px 0px !important;

  &.MuiCheckbox-colorPrimary.Mui-checked {
    color: ${Colors.PURPLE[400]};
  }
`

export const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`
