import React from 'react'
import { Container, CurrentStepContainer, StepDot, Title } from './Steps.styled'

interface StepProps {
  steps: readonly string[]
  currentStep: string
  hiddenSteps: readonly string[]
  setCurrentStep: (currentStep: string) => void
}

const Steps: React.FC<StepProps> = ({
  steps,
  currentStep,
  setCurrentStep,
  hiddenSteps,
}) => {
  const isComponentHidden = hiddenSteps.includes(currentStep)
  const currentStepIndex = steps.findIndex((step) => step === currentStep)

  return isComponentHidden ? null : (
    <Container>
      <Title>{currentStep}</Title>
      <CurrentStepContainer>
        {steps.map((step, index) => {
          const isHidden = hiddenSteps.includes(step)
          const isDisabled = index > currentStepIndex

          return isHidden ? null : (
            <StepDot
              key={step}
              isCurrent={step === currentStep}
              onClick={isDisabled ? undefined : () => setCurrentStep(step)}
              isDisabled={isDisabled}
            ></StepDot>
          )
        })}
      </CurrentStepContainer>
    </Container>
  )
}

export default Steps
