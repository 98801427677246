import ButtonShadow from 'components/ButtonShadow/ButtonShadow'
import { Formik } from 'formik'
import React, { useCallback, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { RecipientFormValues } from '../../RecipientForm'
import * as Styled from './IndividualDataForm.styled'
import NumberFormatField from 'components/Fields/NumberFormatField/NumberFormatField'
import SimpleTextField from 'components/Fields/SimpleTextField/SimpleTextField'

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required('Nome completo não preenchido')
    .test(
      'nameValidation',
      'Você precisa preencher seu nome completo',
      (value) => value?.trim().split(' ').length >= 2,
    ),
  email: Yup.string()
    .trim()
    .required('Email não preenchido')
    .email('Email inválido'),
  birthDate: Yup.string()
    .required('Data de nascimento não preenchida')
    .test('birthDateValidation', 'Data de nascimento inválida', (value) => {
      const [, day, month, year] = value?.match(/^(\d{2})(\d{2})(\d{4})$/) ?? []
      const date = new Date(`${year}-${month}-${day}`)
      return !isNaN(date.getTime())
    }),
  phoneNumber: Yup.string()
    .trim()
    .required('Celular não preenchido')
    .matches(/^\d{11}$/, 'Celular inválido'),
  monthlyIncome: Yup.string().required('Receita mensal não preenchida'),
  professionalOccupation: Yup.string()
    .trim()
    .required('Ocupação profissional não preenchida')
    .min(1),
})

interface FormValues {
  name: string
  email: string
  birthDate: string
  phoneNumber: string
  monthlyIncome: string
  professionalOccupation: string
}

interface RecipientFormProps {
  values: RecipientFormValues
  updateValues: (values: RecipientFormValues, fromStep: string) => Promise<void>
  currentStep: string
  step: string
  previousStep: () => void
}

const IndividualDataForm: React.FC<RecipientFormProps> = ({
  updateValues,
  values,
  currentStep,
  step,
  previousStep,
}) => {
  const [initialValues, setInitialValues] = useState<FormValues>({
    name: values.name,
    email: values.email,
    birthDate: values.birthDate,
    phoneNumber: values.phoneNumber,
    monthlyIncome: values.monthlyIncome,
    professionalOccupation: values.professionalOccupation,
  })

  const onSubmit = useCallback(
    async (formValues: FormValues): Promise<void> => {
      await updateValues(
        {
          ...values,
          name: formValues.name,
          email: formValues.email,
          birthDate: formValues.birthDate,
          phoneNumber: formValues.phoneNumber,
          monthlyIncome: formValues.monthlyIncome,
          professionalOccupation: formValues.professionalOccupation,
        },
        step,
      )
    },
    [updateValues, step, values],
  )

  useEffect(() => {
    setInitialValues({
      name: values.name,
      email: values.email,
      birthDate: values.birthDate,
      phoneNumber: values.phoneNumber,
      monthlyIncome: values.monthlyIncome,
      professionalOccupation: values.professionalOccupation,
    })
  }, [values])

  return currentStep !== step ? null : (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, values, touched, errors }) => {
        return (
          <Styled.FlexForm>
            <Styled.Row>
              <SimpleTextField
                name="name"
                label="Nome completo"
                placeholder="Nome completo"
                variant="outlined"
                error={!!(touched.name && errors?.name)}
                helperText={touched.name && errors?.name}
                fullWidth
                darkMode
              />

              <SimpleTextField
                name="email"
                label="Email"
                placeholder="Email"
                variant="outlined"
                error={!!(touched.email && errors?.email)}
                helperText={touched.email && errors?.email}
                fullWidth
                darkMode
              />
            </Styled.Row>

            <Styled.Row>
              <NumberFormatField
                name="birthDate"
                variant="outlined"
                label="Data de nascimento"
                fullWidth
                format={'##/##/####'}
                placeholder="__/__/____"
                mask={'_'}
                value={values.birthDate}
                error={!!(touched.birthDate && errors?.birthDate)}
                helperText={touched.birthDate && (errors?.birthDate as string)}
                hasEmptyValue
                darkMode
              />

              <NumberFormatField
                name="phoneNumber"
                variant="outlined"
                label="Celular"
                fullWidth
                format={'(##) # ####-####'}
                placeholder="(__) _ ____-____"
                mask={'_'}
                value={values.phoneNumber}
                error={!!(touched.phoneNumber && errors?.phoneNumber)}
                helperText={
                  touched.phoneNumber && (errors?.phoneNumber as string)
                }
                hasEmptyValue
                darkMode
              />
            </Styled.Row>

            <Styled.Row>
              <SimpleTextField
                name="professionalOccupation"
                label="Ocupação profissional"
                placeholder="Ocupação profissional"
                variant="outlined"
                error={
                  !!(
                    touched.professionalOccupation &&
                    errors?.professionalOccupation
                  )
                }
                helperText={
                  touched.professionalOccupation &&
                  errors?.professionalOccupation
                }
                fullWidth
                darkMode
              />

              <NumberFormatField
                name="monthlyIncome"
                label="Receita mensal"
                placeholder="R$"
                decimalSeparator=","
                thousandSeparator="."
                allowNegative={false}
                prefix="R$ "
                decimalScale={2}
                fixedDecimalScale={true}
                value={values.monthlyIncome}
                error={!!(touched.monthlyIncome && errors?.monthlyIncome)}
                helperText={
                  touched.monthlyIncome && (errors?.monthlyIncome as string)
                }
                fullWidth
                darkMode
                infoTooltip="Informação exigida pelas plataformas de pagamento. Você pode preencher com as expectativas de ganho do seu projeto."
              />
            </Styled.Row>

            <Styled.ActionsContainer>
              <ButtonShadow
                label="Voltar"
                variant="secondary"
                onPress={previousStep}
              />
              <ButtonShadow label="Continuar" onPress={handleSubmit} />
            </Styled.ActionsContainer>
          </Styled.FlexForm>
        )
      }}
    </Formik>
  )
}

export default IndividualDataForm
