import React from 'react'
import * as Styled from './RecipientStatusInfo.styled'
import { Cancel, CheckCircle, Warning } from '@material-ui/icons'
import { Colors } from 'styles'

type DisplayableRecipientStatus =
  | 'refused'
  | 'suspended'
  | 'active'
  | 'blocked'
  | 'inactive'

export function isDisplayableStatus(
  status:
    | 'pending'
    | 'registration'
    | 'affiliation'
    | 'refused'
    | 'suspended'
    | 'active'
    | 'blocked'
    | 'inactive',
): status is DisplayableRecipientStatus {
  return ['refused', 'suspended', 'active', 'blocked', 'inactive'].includes(
    status,
  )
}

interface IVariant {
  title: string
  icon: JSX.Element
  description?: JSX.Element
}

const variants: Record<DisplayableRecipientStatus, IVariant> = {
  active: {
    title: 'Ativo',
    icon: (
      <CheckCircle fontSize="large" style={{ color: Colors.PURPLE[400] }} />
    ),
    description: (
      <>
        Seu cadastro está atualizado! A partir do mês que vem, você receberá os
        valores de apoio diretamente em sua conta bancária
        <br />
        <br />
        <span style={{ color: Colors.GREY[200] }}>
          Caso deseje alterar seus dados por favor entre em contato com o
          suporte da Orelo clicando{' '}
          <Styled.Url
            href="https://orelohelp.zendesk.com/hc/pt-br/requests/new"
            target="_blank"
            rel="noopener noreferrer"
          >
            aqui.
          </Styled.Url>
        </span>
      </>
    ),
  },
  refused: {
    title: 'Reprovado',
    icon: <Cancel fontSize="large" style={{ color: Colors.SUPPORT_ERROR }} />,
    description: (
      <>
        Nosso parceiro Pagar.me reprovou seu cadastro durante a análise. Entre
        em contato com o suporte da Orelo clicando{' '}
        <Styled.Url
          href="https://orelohelp.zendesk.com/hc/pt-br/requests/new"
          target="_blank"
          rel="noopener noreferrer"
        >
          aqui.
        </Styled.Url>
      </>
    ),
  },
  suspended: {
    title: 'Inativo',
    icon: <Warning fontSize="large" style={{ color: Colors.SUPPORT_ALERT }} />,
    description: (
      <>
        Houve um problema com seu cadastro no nosso parceiro Pagar.me, por favor
        entre em contato com o suporte da Orelo clicando{' '}
        <Styled.Url
          href="https://orelohelp.zendesk.com/hc/pt-br/requests/new"
          target="_blank"
          rel="noopener noreferrer"
        >
          aqui.
        </Styled.Url>
      </>
    ),
  },
  blocked: {
    title: 'Inativo',
    icon: <Warning fontSize="large" style={{ color: Colors.SUPPORT_ALERT }} />,
    description: (
      <>
        Houve um problema com seu cadastro no nosso parceiro Pagar.me, por favor
        entre em contato com o suporte da Orelo clicando{' '}
        <Styled.Url
          href="https://orelohelp.zendesk.com/hc/pt-br/requests/new"
          target="_blank"
          rel="noopener noreferrer"
        >
          aqui.
        </Styled.Url>
      </>
    ),
  },
  inactive: {
    title: 'Inativo',
    icon: <Warning fontSize="large" style={{ color: Colors.SUPPORT_ALERT }} />,
    description: (
      <>
        Houve um problema com seu cadastro no nosso parceiro Pagar.me, por favor
        entre em contato com o suporte da Orelo clicando{' '}
        <Styled.Url
          href="https://orelohelp.zendesk.com/hc/pt-br/requests/new"
          target="_blank"
          rel="noopener noreferrer"
        >
          aqui.
        </Styled.Url>
      </>
    ),
  },
}

interface RecipientProps {
  recipientStatus: DisplayableRecipientStatus
}

const RecipientStatusInfo: React.FC<RecipientProps> = ({ recipientStatus }) => {
  const variant = variants[recipientStatus]

  return (
    <Styled.Container>
      <Styled.TitleContainer>
        <Styled.Title>{variant.title}</Styled.Title>
        {variant.icon}
      </Styled.TitleContainer>

      {variant.description && (
        <Styled.Description>{variant.description}</Styled.Description>
      )}
    </Styled.Container>
  )
}

export default RecipientStatusInfo
