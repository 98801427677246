import { Linking } from 'react-native-web'
import * as monitor from 'services/monitor'

const openURL = async (url: string): Promise<void> => {
  const isAbleToOpenURL = await Linking.canOpenURL(url)

  if (isAbleToOpenURL) {
    window.open(url, '_blank', 'noopener')
  } else {
    monitor.notify(new Error(`Couldn't open url ${url}`))
    alert('Erro ao abrir URL... Infelizmente, não foi possível abrir esta URL.')
  }
}

export default openURL
