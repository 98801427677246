import React from 'react'
import { useField } from 'formik'
import { FormHelperText, OutlinedTextFieldProps } from '@material-ui/core'
import * as Styled from './SimpleTextField.styled'

interface SimpleTextFieldProps extends OutlinedTextFieldProps {
  name: string
  label?: string
  error?: boolean
  helperText?: string
  fullWidth?: boolean
  darkMode?: boolean
}

const SimpleTextField: React.FC<SimpleTextFieldProps> = ({
  name,
  label,
  error,
  helperText,
  fullWidth,
  darkMode,
  ...props
}) => {
  const [field] = useField(name)

  return (
    <Styled.Wrapper fullWidth={fullWidth}>
      {label && (
        <Styled.StyledLabel htmlFor={name} darkMode={darkMode}>
          {label}
        </Styled.StyledLabel>
      )}
      <Styled.StyledTextField
        id={name}
        darkMode={darkMode}
        error={error}
        {...field}
        {...props}
      />
      {helperText && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
    </Styled.Wrapper>
  )
}

export default SimpleTextField
