import { CircularProgress, Snackbar, TextField } from '@material-ui/core'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import LaunchIcon from '@mui/icons-material/Launch'
import ButtonShadow from 'components/ButtonShadow/ButtonShadow'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { generateKycLink, getRecipientStatus } from 'services/api'
import { Colors } from 'styles'
import openURL from 'utils/openURL'
import * as Styled from './RecipientKyc.styled'
import { Container } from './RecipientKyc.styled'
import { QRCodeSVG } from 'qrcode.react'
import { RecipientStatus } from 'types'

interface IError {
  message?: string
  tryAgainFunctionName?: string
}

interface RecipientProps {
  podcastId: string
  setRecipientStatus: React.Dispatch<React.SetStateAction<RecipientStatus>>
}

const RecipientKyc: React.FC<RecipientProps> = ({
  podcastId,
  setRecipientStatus,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [kycUrl, setKycUrl] = useState<string | null>(null)
  const [error, setError] = useState<IError>({})
  const [copyTextResult, setCopyTextResult] = useState<string | undefined>(
    undefined,
  )
  const inputUrlRef = useRef<HTMLInputElement>(null)

  const fetchKycUrl = useCallback(async () => {
    setIsLoading(true)
    const kycLinkOrError = await generateKycLink(podcastId)

    if (kycLinkOrError.hasFailed()) {
      setIsLoading(false)

      setError({
        message: 'Falha ao continuar cadastro.',
        tryAgainFunctionName: 'fetchKycUrl',
      })
      return
    }

    setIsLoading(false)
    setKycUrl(kycLinkOrError.data.url)
  }, [podcastId])

  useEffect(() => {
    fetchKycUrl()
  }, [fetchKycUrl])

  const handleCopyLink = useCallback(async () => {
    inputUrlRef.current?.select()
    const success = document.execCommand('copy')
    setCopyTextResult(success ? 'Link Copiado!' : 'Erro ao copiar link')
    inputUrlRef.current?.blur()
  }, [])

  const handleOpenLink = useCallback(async () => {
    await openURL(kycUrl)
  }, [kycUrl])

  const fetchRecipientStatus = useCallback(async () => {
    const statusOrError = await getRecipientStatus(podcastId)

    if (statusOrError.hasFailed()) {
      return
    }

    if (statusOrError.data.status !== 'affiliation') {
      setRecipientStatus(statusOrError.data.status)
    }
  }, [podcastId])

  useEffect(() => {
    const interval = setInterval(() => {
      fetchRecipientStatus()
    }, 5000)

    return () => clearInterval(interval)
  }, [fetchRecipientStatus])

  return (
    <Container>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={!!copyTextResult}
        onClose={() => setCopyTextResult(undefined)}
        message={copyTextResult}
        autoHideDuration={3000}
      />

      {isLoading ? (
        <Styled.InfoContainer>
          <CircularProgress style={{ color: Colors.PURPLE[400] }} />
          <Styled.InfoText>Carregando...</Styled.InfoText>
        </Styled.InfoContainer>
      ) : error.message ? (
        <Styled.InfoContainer>
          <Styled.InfoText>{error.message}</Styled.InfoText>
          <ButtonShadow
            label="Tentar novamente"
            variant="secondary"
            onPress={
              error.tryAgainFunctionName === 'fetchKycUrl'
                ? fetchKycUrl
                : undefined
            }
          />
        </Styled.InfoContainer>
      ) : (
        <Styled.KycContainer>
          <Styled.KycInfoContainer>
            <Styled.KycInfoTitle>
              Continue seu cadastro pelo celular usando o QRCode abaixo
            </Styled.KycInfoTitle>

            <Styled.KycInfoDescription>
              Você será redirecionado para a página da plataforma de pagamentos
              <br />
              Se preferir, utilize o link abaixo
              <br />
              Você irá precisar de uma webcam
            </Styled.KycInfoDescription>

            <Styled.KycInfoWarning>
              Após finalizar o processo, você poderá verificar o status do seu
              registro novamente nesta página
            </Styled.KycInfoWarning>
          </Styled.KycInfoContainer>

          <Styled.QrCodeContainer>
            <QRCodeSVG value={kycUrl} size={256} marginSize={4} />
          </Styled.QrCodeContainer>

          <Styled.LinkContainer>
            <TextField
              type="text"
              value={kycUrl}
              onChange={() => {}}
              variant="outlined"
              fullWidth
              inputRef={inputUrlRef}
            />

            <Styled.LinkActions>
              <ButtonShadow
                leftIcon={<ContentCopyIcon style={{ color: 'white' }} />}
                onPress={handleCopyLink}
              />
              <ButtonShadow
                leftIcon={<LaunchIcon style={{ color: 'white' }} />}
                onPress={handleOpenLink}
              />
            </Styled.LinkActions>
          </Styled.LinkContainer>
        </Styled.KycContainer>
      )}
    </Container>
  )
}

export default RecipientKyc
