import { Form } from 'formik'
import styled from 'styled-components'

export const FlexForm = styled(Form)`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: start;
  padding: 0px 0px 24px 0px;
`

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 4px;
`

export const Row = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
`
