import ButtonShadow from 'components/ButtonShadow/ButtonShadow'
import { Formik } from 'formik'
import React, { useCallback, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { RecipientFormValues } from '../../RecipientForm'
import * as Styled from './CompanyDataForm.styled'
import NumberFormatField from 'components/Fields/NumberFormatField/NumberFormatField'
import SimpleTextField from 'components/Fields/SimpleTextField/SimpleTextField'

const validationSchema = Yup.object().shape({
  email: Yup.string().required('Email não preenchido').email('Email inválido'),
  phoneNumber: Yup.string()
    .trim()
    .required('Celular não preenchido')
    .matches(/^\d{11}$/, 'Celular inválido'),
  companyName: Yup.string().trim().required('Razão social não preenchida'),
  annualRevenue: Yup.string().required('Receita anual não preenchida'),
})

interface FormValues {
  email: string
  phoneNumber: string
  companyName: string
  annualRevenue: string
}

interface RecipientFormProps {
  values: RecipientFormValues
  updateValues: (values: RecipientFormValues, fromStep: string) => Promise<void>
  currentStep: string
  step: string
  previousStep: () => void
}

const CompanyDataForm: React.FC<RecipientFormProps> = ({
  updateValues,
  values,
  currentStep,
  step,
  previousStep,
}) => {
  const [initialValues, setInitialValues] = useState<FormValues>({
    email: values.email,
    phoneNumber: values.phoneNumber,
    companyName: values.companyName,
    annualRevenue: values.annualRevenue,
  })

  const onSubmit = useCallback(
    async (formValues: FormValues): Promise<void> => {
      await updateValues(
        {
          ...values,
          email: formValues.email,
          phoneNumber: formValues.phoneNumber,
          companyName: formValues.companyName,
          annualRevenue: formValues.annualRevenue,
        },
        step,
      )
    },
    [updateValues, step, values],
  )

  useEffect(() => {
    setInitialValues({
      email: values.email,
      phoneNumber: values.phoneNumber,
      companyName: values.companyName,
      annualRevenue: values.annualRevenue,
    })
  }, [values])

  return currentStep !== step ? null : (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, values, touched, errors }) => {
        return (
          <Styled.FlexForm>
            <Styled.Row>
              <SimpleTextField
                name="email"
                label="Email"
                placeholder="Email"
                variant="outlined"
                error={!!(touched.email && errors?.email)}
                helperText={touched.email && errors?.email}
                fullWidth
                darkMode
              />

              <NumberFormatField
                name="phoneNumber"
                variant="outlined"
                label="Celular"
                fullWidth
                format={'(##) # ####-####'}
                placeholder="(__) _ ____-____"
                mask={'_'}
                value={values.phoneNumber}
                error={!!(touched.phoneNumber && errors?.phoneNumber)}
                helperText={
                  touched.phoneNumber && (errors?.phoneNumber as string)
                }
                hasEmptyValue
                darkMode
              />
            </Styled.Row>

            <Styled.Row>
              <SimpleTextField
                name="companyName"
                label="Razão social"
                placeholder="Razão social"
                variant="outlined"
                error={!!(touched.companyName && errors?.companyName)}
                helperText={touched.companyName && errors?.companyName}
                fullWidth
                darkMode
              />
            </Styled.Row>

            <Styled.Row>
              <NumberFormatField
                name="annualRevenue"
                label="Receita anual"
                placeholder="R$"
                decimalSeparator=","
                thousandSeparator="."
                allowNegative={false}
                prefix="R$ "
                decimalScale={2}
                fixedDecimalScale={true}
                value={values.annualRevenue}
                error={!!(touched.annualRevenue && errors?.annualRevenue)}
                helperText={
                  touched.annualRevenue && (errors?.annualRevenue as string)
                }
                fullWidth
                darkMode
                infoTooltip="Informação exigida pelas plataformas de pagamento. Você pode preencher com as expectativas de ganho do seu projeto."
              />
            </Styled.Row>

            <Styled.ActionsContainer>
              <ButtonShadow
                label="Voltar"
                variant="secondary"
                onPress={previousStep}
              />
              <ButtonShadow label="Continuar" onPress={handleSubmit} />
            </Styled.ActionsContainer>
          </Styled.FlexForm>
        )
      }}
    </Formik>
  )
}

export default CompanyDataForm
