import { FormHelperText } from '@material-ui/core'
import { useField } from 'formik'
import React from 'react'
import ReactSelect from 'react-select'
import { Colors } from 'styles'
import * as Styled from './AutocompleteField.styled'

interface Option {
  label: string
  value: string
}

interface SelectFieldProps {
  name: string
  label?: string | React.ReactElement
  helperText?: string
  error?: boolean
  fullWidth?: boolean
  darkMode?: boolean
  wrapperStyle?: React.CSSProperties
  options: Option[]
}

const AutocompleteField: React.FC<SelectFieldProps> = ({
  name,
  label,
  helperText,
  error,
  fullWidth,
  darkMode,
  wrapperStyle,
  options,
}) => {
  const [field, , helpers] = useField(name)

  return (
    <Styled.Wrapper fullWidth={fullWidth} style={wrapperStyle}>
      {label && (
        <Styled.StyledLabel htmlFor={name} darkMode={darkMode}>
          {label}
        </Styled.StyledLabel>
      )}
      <ReactSelect
        name={name}
        options={options}
        styles={{
          indicatorsContainer: (baseStyles) => ({
            ...baseStyles,
            display: 'none',
          }),
          indicatorSeparator: (baseStyles) => ({
            ...baseStyles,
            display: 'none',
          }),
          control: (baseStyles) => ({
            ...baseStyles,
            border: `1px solid ${error ? '#f44336' : 'black'}`,
            borderColor: `${error ? '#f44336' : 'black'} !important`,
            boxShadow: 'none',
            borderRadius: '24px',
            fontSize: '18px',
            fontFamily: 'Inter',
            padding: '6px 8px',
          }),
          menu: (baseStyles) => ({
            ...baseStyles,
            fontSize: '1rem',
            fontFamily: 'Inter',
          }),
        }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: Colors.GREY[150],
            primary: Colors.PURPLE[400],
          },
        })}
        noOptionsMessage={() => 'Opção não encontrada'}
        onBlur={() => {
          helpers.setTouched(true, true)
        }}
        onChange={(option: Option) => {
          helpers.setTouched(true, true)
          helpers.setValue(option.value, true)
        }}
        value={options.find(({ value }) => value === field.value)}
      />
      {helperText && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
    </Styled.Wrapper>
  )
}

export default AutocompleteField
