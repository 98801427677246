import ButtonShadow from 'components/ButtonShadow/ButtonShadow'
import NumberFormatField from 'components/Fields/NumberFormatField/NumberFormatField'
import { cnpj, cpf } from 'cpf-cnpj-validator'
import { Formik } from 'formik'
import React, { useCallback, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { RecipientFormValues } from '../../RecipientForm'
import * as Styled from './DocumentNumberForm.styled'
import SimpleCheckboxField from 'components/Fields/SimpleCheckboxField/SimpleCheckboxField'

const validationSchema = Yup.object().shape({
  documentNumber: Yup.string()
    .required('CPF/CNPJ não preenchido')
    .test(
      'documentNumberValidation',
      'CPF/CNPJ inválido',
      (value) => cpf.isValid(value) || cnpj.isValid(value),
    ),
  acceptTerms: Yup.string().required(
    'Você precisa aceitar os termos e condições e políticas de privacidade',
  ),
})

interface FormValues {
  acceptTerms: string
  documentNumber: string
}

interface RecipientFormProps {
  values: RecipientFormValues
  updateValues: (values: RecipientFormValues, fromStep: string) => Promise<void>
  currentStep: string
  step: string
}

const DocumentNumberForm: React.FC<RecipientFormProps> = ({
  updateValues,
  values,
  currentStep,
  step,
}) => {
  const [initialValues, setInitialValues] = useState<FormValues>({
    documentNumber: values.documentNumber,
    acceptTerms: '',
  })
  const onSubmit = useCallback(
    async (formValues: FormValues): Promise<void> => {
      updateValues(
        { ...values, documentNumber: formValues.documentNumber },
        step,
      )
    },
    [updateValues, step, values],
  )

  useEffect(() => {
    setInitialValues({
      documentNumber: values.documentNumber,
      acceptTerms: '',
    })
  }, [values])

  const renderTermsLabel = () => (
    <span>
      Aceito os{' '}
      <Styled.TermsLink
        href="https://orelo.audio/legal/tcuser"
        target="_blank"
        rel="noopener noreferrer"
      >
        termos e condição
      </Styled.TermsLink>{' '}
      e as{' '}
      <Styled.TermsLink
        href="https://orelo.audio/legal/pp"
        target="_blank"
        rel="noopener noreferrer"
      >
        políticas de privacidade
      </Styled.TermsLink>
    </span>
  )

  return currentStep !== step ? null : (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, values, touched, errors }) => {
        return (
          <Styled.FlexForm>
            <Styled.InfoText>
              Olá, Creator. <br />
              Continue o cadastro abaixo para atualizar as suas informações e
              receber seus pagamentos automaticamente.
            </Styled.InfoText>

            <NumberFormatField
              name="documentNumber"
              label="CPF/CNPJ"
              placeholder="Seu CPF/CNPJ"
              format={
                values.documentNumber?.length > 11
                  ? '##.###.###/####-##'
                  : '###.###.###-## ###'
              }
              variant="outlined"
              fullWidth
              mask=""
              value={values.documentNumber}
              error={!!(touched.documentNumber && errors?.documentNumber)}
              helperText={
                touched.documentNumber && (errors?.documentNumber as string)
              }
              hasEmptyValue
              darkMode
            />

            <SimpleCheckboxField
              name="acceptTerms"
              label={renderTermsLabel()}
              error={!!(touched.acceptTerms && errors?.acceptTerms)}
              helperText={
                touched.acceptTerms && (errors?.acceptTerms as string)
              }
              darkMode
            />

            <Styled.ActionsContainer>
              <ButtonShadow label="Continuar" onPress={handleSubmit} />
            </Styled.ActionsContainer>
          </Styled.FlexForm>
        )
      }}
    </Formik>
  )
}

export default DocumentNumberForm
