import { Form } from 'formik'
import styled from 'styled-components'
import { Colors, Typography } from 'styles'

export const FlexForm = styled(Form)`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: start;
  padding: 24px 0;
`

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`

export const TermsLink = styled.a`
  color: ${Colors.PURPLE[400]};
`

export const InfoText = styled.p`
  font-weight: 400;
  font-size: 1.2rem;
  color: ${Colors.GREY[400]};
  font-family: Inter;
  letter-spacing: -${Typography.LETTER_SPACING_1}px;
  margin: 0;
  margin-bottom: 10px;
`
