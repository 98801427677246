import styled from 'styled-components'
import { Colors, Typography } from 'styles'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`

export const InfoContainer = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
`

export const InfoText = styled.p`
  font-weight: 300;
  font-size: 1.2rem;
  color: ${Colors.GREY[400]};
  font-family: Inter;
  letter-spacing: -${Typography.LETTER_SPACING_1}px;
  text-align: center;
`

export const KycContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 24px;
`

export const KycInfoContainer = styled.div`
  padding-top: 20px;
`

export const KycInfoTitle = styled.p`
  font-weight: 800;
  font-size: 1.2rem;
  color: ${Colors.GREY[400]};
  font-family: Inter;
  letter-spacing: -${Typography.LETTER_SPACING_1}px;
  text-align: center;
`

export const KycInfoDescription = styled.p`
  font-weight: 300;
  font-size: 1rem;
  color: ${Colors.GREY[400]};
  font-family: Inter;
  letter-spacing: -${Typography.LETTER_SPACING_1}px;
  text-align: center;
`

export const KycInfoWarning = styled.p`
  font-weight: 800;
  font-size: 1rem;
  color: ${Colors.GREY[400]};
  font-family: Inter;
  letter-spacing: -${Typography.LETTER_SPACING_1}px;
  text-align: center;
`

export const QrCodeContainer = styled.div``

export const LinkContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;

  @media (max-width: 500px) {
    flex-direction: column;
  }
`

export const LinkActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`
